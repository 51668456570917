import { FalconPaginationMeta } from '@om1/falcon-api'

export enum AnalyticsRefDimension {
    RACE = 'RACE',
    SEX = 'SEX',
    STATE = 'STATE',
    DIAGNOSIS_ICD10 = 'DIAGNOSIS_ICD10',
    DIAGNOSIS_ICD10_GEM_MAPPED = 'DIAGNOSIS_ICD10_GEM_MAPPED',
    DIAGNOSIS_CUI = 'DIAGNOSIS_CUI',
    LAB = 'LAB',
    MEDICATION = 'MEDICATION',
    MEDICATION_CUI = 'MEDICATION_CUI',
    MEDICATION_NDC = 'MEDICATION_NDC',
    OBSERVATION = 'OBSERVATION',
    PROCEDURE = 'PROCEDURE',
    SPECIALTY = 'SPECIALTY',
    PHENOTYPE = 'PHENOTYPE',
    PRODUCT_CUSTOM_COHORT = 'PRODUCT_CUSTOM_COHORT'
}

export enum AnalyticsRefDestination {
    SEARCH = 'SEARCH',
    RESTORE = 'RESTORE'
}

export interface AnalyticsRefsState {
    [AnalyticsRefDimension.RACE]: RefStateDestinations<RaceRef>
    [AnalyticsRefDimension.SEX]: RefStateDestinations<SexRef>
    [AnalyticsRefDimension.STATE]: RefStateDestinations<StateRef>
    [AnalyticsRefDimension.DIAGNOSIS_ICD10]: RefStateDestinations<DiagnosisIcd10Ref>
    [AnalyticsRefDimension.DIAGNOSIS_ICD10_GEM_MAPPED]: RefStateDestinations<DiagnosisIcd10Ref>
    [AnalyticsRefDimension.DIAGNOSIS_CUI]: RefStateDestinations<DiagnosisCuiRef>
    [AnalyticsRefDimension.LAB]: RefStateDestinations<LabRef>
    [AnalyticsRefDimension.MEDICATION]: RefStateDestinations<MedicationRef>
    [AnalyticsRefDimension.MEDICATION_CUI]: RefStateDestinations<MedicationCuiRef>
    [AnalyticsRefDimension.MEDICATION_NDC]: RefStateDestinations<MedicationNdcRef>
    [AnalyticsRefDimension.OBSERVATION]: RefStateDestinations<ObservationRef>
    [AnalyticsRefDimension.PROCEDURE]: RefStateDestinations<ProcedureRef>
    [AnalyticsRefDimension.SPECIALTY]: RefStateDestinations<SpecialtyRef>
    [AnalyticsRefDimension.PHENOTYPE]: RefStateDestinations<PhenotypeRef>
    [AnalyticsRefDimension.PRODUCT_CUSTOM_COHORT]: RefStateDestinations<ProductCustomCohortRef>
}

interface RefStateDestinations<T> {
    [AnalyticsRefDestination.SEARCH]: RefState<T>
    [AnalyticsRefDestination.RESTORE]: RefState<T>
}

interface RefState<T> {
    data: T[]
    paginationMeta: FalconPaginationMeta
}

export interface RaceRef {
    race: string
}

export interface SexRef {
    sex: string
}

export interface StateRef {
    stateName: string
    subRegion: string
    region: string
}

export interface DiagnosisIcd10Ref {
    diagnosisConceptId: string
    diagnosis: string
    parentDiagnosisConceptId: string | null
    childCount: string | null
    pathToRoot: string | null
    level: string
}

export interface DiagnosisCuiRef {
    bocCui: string
    bocName: string
    bocDisplayName: string
    parentBocCui: string | null
}

export interface LabRef {
    labConceptId: string
    loincCode: string | null
    lab: string
    parentLabConceptId: string | null
    pathToRoot: string | null
    displayName: string
    childCount: string | null
}

export interface MedicationRef {
    id: string
    medication: string
    drugRouteId: string
    routeOfAdmin: string
    drugConceptId: string
    level0Id: string | null
    level0Name: string | null
    level1Id: string | null
    level1Name: string | null
    level2Id: string | null
    level2Name: string | null
    level3Id: string | null
    level3Name: string | null
    level4Id: string | null
    level4Name: string | null
}

export interface MedicationCuiRef {
    bocCui: string
    bocName: string
    bocDisplayName: string
    parentBocCui: string | null
}

export interface MedicationNdcRef {
    ndcCode: string
    ndcName: string
    ndcDisplayName: string
}

export interface ObservationRef {
    observation: string
}

export interface ProcedureRef {
    procedureConceptId: string
    procedure: string
    displayName: string
}

export interface EhrNotesRef {}

export interface SpecialtyRef {
    specialty: string
    specialtyConceptId: string
    parentSpecialtyConceptId: string | null
    pathToRoot: string | null
    specialtyCui: string | null
}

export interface PhenotypeRef {
    phenotype: string
}

export interface ProductCustomCohortRef {
    cohort: string
}

const REF_DEFAULT = {
    [AnalyticsRefDestination.SEARCH]: { data: [], paginationMeta: { currentPage: 0, itemsPerPage: 0, totalItems: 0, totalPages: 0 } },
    [AnalyticsRefDestination.RESTORE]: { data: [], paginationMeta: { currentPage: 0, itemsPerPage: 0, totalItems: 0, totalPages: 0 } }
}

export const initialAnalyticsRefsState: AnalyticsRefsState = {
    [AnalyticsRefDimension.RACE]: REF_DEFAULT,
    [AnalyticsRefDimension.SEX]: REF_DEFAULT,
    [AnalyticsRefDimension.STATE]: REF_DEFAULT,
    [AnalyticsRefDimension.DIAGNOSIS_ICD10]: REF_DEFAULT,
    [AnalyticsRefDimension.DIAGNOSIS_ICD10_GEM_MAPPED]: REF_DEFAULT,
    [AnalyticsRefDimension.DIAGNOSIS_CUI]: REF_DEFAULT,
    [AnalyticsRefDimension.LAB]: REF_DEFAULT,
    [AnalyticsRefDimension.MEDICATION]: REF_DEFAULT,
    [AnalyticsRefDimension.MEDICATION_CUI]: REF_DEFAULT,
    [AnalyticsRefDimension.MEDICATION_NDC]: REF_DEFAULT,
    [AnalyticsRefDimension.OBSERVATION]: REF_DEFAULT,
    [AnalyticsRefDimension.PROCEDURE]: REF_DEFAULT,
    [AnalyticsRefDimension.SPECIALTY]: REF_DEFAULT,
    [AnalyticsRefDimension.PHENOTYPE]: REF_DEFAULT,
    [AnalyticsRefDimension.PRODUCT_CUSTOM_COHORT]: REF_DEFAULT
}
