import { Trans } from '@lingui/macro'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import { Auth0State } from '@om1/platform-authentication'
import { LightTooltip } from '@om1/platform-components/Tooltip'
import { connectRedux } from '@om1/platform-utils'
import {
    AnalyticsRefDestination,
    CohortState,
    DiagnosisCuiRef,
    DiagnosisIcd10Ref,
    LabRef,
    MedicationCuiRef,
    MedicationNdcRef,
    MedicationRef,
    ObservationRef,
    PhenotypeRef,
    ProcedureRef,
    SpecialtyRef,
    cohortBlocksEditActions,
    ProductCustomCohortRef
} from '../../../state'
import { analyticsRefActions } from '../../../state/refs/analytics-refs-actions'
import { RefFieldMapperConfig } from '../base/CohortEditComponent'
import {
    DiagnosisCuiRefFieldMapper,
    DiagnosisGemMappedIcd10RefFieldMapper,
    DiagnosisIcd10RefFieldMapper,
    LabRefFieldMapper,
    MedicationCuiRefFieldMapper,
    MedicationNdcRefFieldMapper,
    MedicationRefFieldMapper,
    ObservationRefFieldMapper,
    PhenotypeRefFieldMapper,
    ProcedureRefFieldMapper,
    SpecialtyRefFieldMapper
} from '../utils/ref-field-mappers'
import { RefTreeDialogComponent } from './RefTreeDialogComponent'
import { ProductCustomCohortRefFieldMapper } from '../utils/ref-field-mappers/product-custom-cohort-ref-field-mapper'

export function createRefTreeDialog<TState extends { cohort: CohortState; auth0: Auth0State }>() {
    return connectRedux(
        RefTreeDialogComponent,
        (state: TState, { fieldMappers }) => {
            const dimensions = fieldMappers.map((fm) => fm.mapper.dimension)

            return {
                refs: dimensions.reduce((o, key) => Object.assign(o, { [key]: state.cohort.refs[key][AnalyticsRefDestination.SEARCH].data }), {}),
                refsRestore: dimensions.reduce(
                    (o, key) => Object.assign(o, { [key]: state.cohort.refs[key][AnalyticsRefDestination.RESTORE].data }),
                    {}
                ),
                searchLoading: dimensions.reduce(
                    (o, key) => Object.assign(o, { [key]: state.cohort.edit.blocks.ui.refsLoading[key][AnalyticsRefDestination.SEARCH] }),
                    {}
                ),
                restoreLoading: dimensions.reduce(
                    (o, key) => Object.assign(o, { [key]: state.cohort.edit.blocks.ui.refsLoading[key][AnalyticsRefDestination.RESTORE] }),
                    {}
                ),
                searchPaginationMeta: dimensions.reduce(
                    (o, key) => Object.assign(o, { [key]: state.cohort.refs[key][AnalyticsRefDestination.SEARCH].paginationMeta }),
                    {}
                ),
                isOM1User: (state.auth0.user?.email || '').endsWith('@om1.com')
            }
        },
        { ...cohortBlocksEditActions, setRefs: analyticsRefActions.setRefs }
    )
}

export type DiagnosisDialog = ReturnType<typeof createRefTreeDialog>

export const DIAGNOSIS_REF_FIELD_MAPPER_CONFIG: [
    RefFieldMapperConfig<DiagnosisCuiRef>,
    RefFieldMapperConfig<DiagnosisIcd10Ref>,
    RefFieldMapperConfig<DiagnosisIcd10Ref>
] = [
    {
        label: <Trans>Search OM1 Coding System</Trans>,
        mapper: DiagnosisCuiRefFieldMapper,
        id: 'diagnosis-cui-mapper',
        isInternal: true
    },
    {
        mapper: DiagnosisIcd10RefFieldMapper,
        id: 'icd-10-mapper',
        label: <Trans>Search ICD10 Classification</Trans>
    },
    {
        mapper: DiagnosisGemMappedIcd10RefFieldMapper,
        id: 'gem-mapped-icd-10-mapper',
        label: (
            <Box display='flex' alignContent='center' columnGap={1}>
                <Trans>Search GEM Mapped ICD Classifications</Trans>
                <LightTooltip
                    maxWidth='500px'
                    placement='top-start'
                    title={
                        <Typography>
                            <Trans>
                                A modified set of General Equivalence Mappings (GEMs), obtained from CMS and adjusted for improved accuracy by OM1,
                                are being used to map ICD-9 codes to ICD-10 codes. When using this option, selecting a concept from the ICD-10 code
                                hierarchy will include patients coded with the acceptably equivalent ICD-9 codes.
                            </Trans>
                        </Typography>
                    }
                >
                    <InfoOutlinedIcon />
                </LightTooltip>
            </Box>
        )
    }
]

export const LAB_REF_FIELD_MAPPER_CONFIG: [RefFieldMapperConfig<LabRef>] = [
    { label: <Trans>Search Labs</Trans>, mapper: LabRefFieldMapper, id: 'lab-mapper' }
]

export const MEDICATION_REF_FIELD_MAPPER_CONFIG: [
    RefFieldMapperConfig<MedicationCuiRef>,
    RefFieldMapperConfig<MedicationRef>,
    RefFieldMapperConfig<MedicationNdcRef>
] = [
    {
        label: <Trans>Search OM1 Coding System</Trans>,
        mapper: MedicationCuiRefFieldMapper,
        id: 'medication-cui-mapper',
        isInternal: true
    },
    {
        label: <Trans>Search Medication Classifications</Trans>,
        mapper: MedicationRefFieldMapper,
        id: 'medication-mapper',
        searchInfo: (
            <Trans>
                <strong>Warning:</strong> In order for a route of administration to be queried, you must select the route at the drug level [e.g.
                ruxolitinib (topical)]. If you select a category instead [e.g. Dermatologicals, Topical], the system selects all routes by default,
                despite what&apos;s indicated in the label. We are aware of the issue and have work planned to implement a fix.
            </Trans>
        )
    },
    {
        label: <Trans>Search National Drug Code Directory</Trans>,
        mapper: MedicationNdcRefFieldMapper,
        id: 'medication-ndc-mapper'
    }
]

export const OBSERVATION_REF_FIELD_MAPPER_CONFIG: [RefFieldMapperConfig<ObservationRef>] = [
    { label: <Trans>Search Observations</Trans>, mapper: ObservationRefFieldMapper, id: 'observation-mapper' }
]

export const PROCEDURE_REF_FIELD_MAPPER_CONFIG: [RefFieldMapperConfig<ProcedureRef>] = [
    { label: <Trans>Search Procedures</Trans>, mapper: ProcedureRefFieldMapper, id: 'procedure-mapper' }
]

export const SPECIALTY_REF_FIELD_MAPPER_CONFIG: [RefFieldMapperConfig<SpecialtyRef>] = [
    { label: <Trans>Search Provider Specialties</Trans>, mapper: SpecialtyRefFieldMapper, id: 'specialty-mapper' }
]

export const EXTERNAL_COHORT_REF_FIELD_MAPPER_CONFIG: [RefFieldMapperConfig<ProductCustomCohortRef>, RefFieldMapperConfig<PhenotypeRef>] = [
    {
        label: <Trans>Search Product Custom Cohort</Trans>,
        mapper: ProductCustomCohortRefFieldMapper,
        id: 'product-custom-cohort-mapper',
        isInternal: true
    },
    {
        label: <Trans>Search PhenOM Characteristics</Trans>,
        mapper: PhenotypeRefFieldMapper,
        id: 'phenotype-mapper'
    }
]
