/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { Trans, t } from '@lingui/macro'
import AddIcon from '@mui/icons-material/Add'
import BarChartIcon from '@mui/icons-material/BarChart'
import CloseFullscreenTwoToneIcon from '@mui/icons-material/CloseFullscreenTwoTone'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import OpenInFullIcon from '@mui/icons-material/OpenInFull'
import { Box, Grid, IconButton, Paper, Typography, useTheme } from '@mui/material'
import { FalconPaginationMeta } from '@om1/falcon-api'
import { FrameworkButton } from '@om1/platform-components'
import { BasicCardStat } from '@om1/platform-components/BasicCard'
import { LightTooltip } from '@om1/platform-components/Tooltip'
import { Routes, toPath } from '@om1/platform-routing'
import { TrackingPlanEvents, trackingActions } from '@om1/platform-tracking'
import { PlatformPermissions, RoutedFrameworkComponentProps } from '@om1/platform-utils'
import { ChangeEventHandler, FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { createGenerateReportComponent } from '../../components/buttons/GenerateReport'
import { createCreateCohortWizardDialogComponent } from '../../components/create/CreateCohortWizardDialog'
import { useOnScreen } from '../../components/edit/utils/useOnScreen'
import { sortByCohortField } from '../../components/list/utils/cohort-list-utils'
import { CohortSizeWithLoader } from '../../components/shared/CohortSizeWithLoader'
import { CohortSaveValues } from '../../components/shared/CreateEditCohortComponent'
import { DatasetSize } from '../../components/shared/DatasetSize'
import { CohortRoutes } from '../../routes'
import {
    CohortListItem,
    CohortLoading,
    CohortTrackingValues,
    ReportAnalyticsType,
    cohortCommonActions,
    cohortCreateActions,
    cohortDeleteActions,
    cohortListActions,
    reportsActions
} from '../../state'
import { CohortDashboardCohortCard } from './CohortDashboardCohortCard'
import { DashboardCohortList } from './DashboardCohortList'
import { DashboardCohortReports } from './DashboardCohortReports'

const WizardDialog = createCreateCohortWizardDialogComponent()

export type CohortDashboardPageComponentProps = RoutedFrameworkComponentProps<
    { reportAnalyticsType: ReportAnalyticsType; search?: string; collapsed?: boolean },
    { cohortOwner: DashboardCohortOwner; cohortId: string; reportType: ReportAnalyticsType },
    {
        cohorts: CohortListItem[]
        datasets: CohortListItem[]
        paginationMeta: FalconPaginationMeta
        createLoading: boolean
        cohortsLoading: boolean
        cohortSizeLoading: CohortLoading
        permissions?: string[]
    },
    typeof cohortListActions &
        typeof trackingActions &
        typeof reportsActions &
        typeof cohortCreateActions &
        Pick<typeof cohortDeleteActions, 'cohortDelete'> &
        Pick<typeof cohortCommonActions, 'cohortSizeGet'> &
        Pick<typeof trackingActions, 'trackEvent'>,
    {}
>

export enum DashboardCohortOwner {
    CUSTOM = 'custom',
    DATASET = 'dataset'
}

const TOP_BAR_HEIGHT = 80

export const CohortDashboardPageComponent: FunctionComponent<CohortDashboardPageComponentProps> = ({ state, actions, routing }) => {
    const { permissions = [] } = state
    const history = useHistory()
    const location = useLocation()
    const theme = useTheme()
    const [isExpand, setIsExpand] = useState(false)
    const GenerateReportComponent = createGenerateReportComponent()
    const sortedCohorts = useMemo(() => {
        return [...state.cohorts].sort(sortByCohortField('queryUpdatedDttm', 'desc'))
    }, [state.cohorts])
    const sortedDatasets = useMemo(() => {
        return [...state.datasets].sort(sortByCohortField('name', 'asc'))
    }, [state.datasets])

    const [createModalVisible, setCreateModalVisible] = useState(false)
    const [cohortOwner, setCohortOwner] = useState<DashboardCohortOwner | undefined>(routing.pathParams.cohortOwner)
    const [cohortId, setCohortId] = useState<string | undefined>(routing.pathParams.cohortId)

    useEffect(() => {
        if (cohortOwner === undefined) {
            history.replace(`/${CohortRoutes.COHORTBUILD}/${CohortRoutes.DASHBOARD}/${DashboardCohortOwner.DATASET}/${window.location.search}`)
            setCohortOwner(DashboardCohortOwner.DATASET)
        } else if (!cohortId && !state.cohortsLoading) {
            if (cohortOwner === DashboardCohortOwner.CUSTOM && sortedCohorts.length > 0) {
                history.replace(
                    `/${CohortRoutes.COHORTBUILD}/${CohortRoutes.DASHBOARD}/${DashboardCohortOwner.CUSTOM}/${sortedCohorts[0].id}/${location.search}`
                )
                setCohortId(sortedCohorts[0].id)
                setCohortOwner(DashboardCohortOwner.CUSTOM)
            } else if (sortedDatasets.length > 0 && cohortOwner === DashboardCohortOwner.DATASET) {
                history.replace(
                    `/${CohortRoutes.COHORTBUILD}/${CohortRoutes.DASHBOARD}/${DashboardCohortOwner.DATASET}/${sortedDatasets[0].id}/${location.search}`
                )
                setCohortId(sortedDatasets[0].id)
                setCohortOwner(DashboardCohortOwner.DATASET)
            }
        }
    }, [sortedCohorts, sortedDatasets, state.cohortsLoading, cohortId, cohortOwner, history, location.search])

    const [reportType, setReportType] = useState<ReportAnalyticsType>(routing.pathParams.reportType ?? ReportAnalyticsType.DEMOGRAPHICS)

    const handleSearchChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
        routing.updateQuery({ search: event.target.value })
    }

    const selectedCohort = useMemo(() => {
        if (cohortOwner === DashboardCohortOwner.DATASET) {
            return state.datasets.find((cohort) => cohort.id === cohortId)
        } else {
            return state.cohorts.find((cohort) => cohort.id === cohortId)
        }
    }, [cohortOwner, state.datasets, state.cohorts, cohortId])

    const handleEditCohort = useCallback(
        (payload: CohortTrackingValues) => {
            actions.trackEvent(TrackingPlanEvents.EDIT_COHORT, payload)
        },
        [actions]
    )

    const dateFormatter = new Intl.DateTimeFormat(undefined, { dateStyle: 'short' })
    const sizeLoading = state.cohortSizeLoading
    const Card = (props: { reportType: ReportAnalyticsType }) => {
        let cardActions: JSX.Element[] = []
        cardActions.push(<GenerateReportComponent cohort={selectedCohort} />)
        if (selectedCohort !== undefined) {
            cardActions.push(
                <LightTooltip title={t`Reports`} placement='top'>
                    <IconButton
                        key='distribution'
                        color='primary'
                        component={Link}
                        to={toPath(Routes.COHORTBUILD) + `/${CohortRoutes.REPORTS}/${selectedCohort.id}/${props.reportType}`}
                        aria-label={t`Reports`}
                        sx={{ backgroundColor: '#D6E4F2', color: '#012D72', borderRadius: '2px', margin: '5px' }}
                    >
                        <BarChartIcon />
                    </IconButton>
                </LightTooltip>
            )
            if (!selectedCohort.isSystem && permissions?.includes(PlatformPermissions.UPDATE_COHORT)) {
                cardActions.push(
                    <LightTooltip title={t`Edit Cohort`} placement='top'>
                        <IconButton
                            key='edit'
                            color='primary'
                            component={Link}
                            to={toPath(Routes.COHORTBUILD) + `/custom/${selectedCohort.id}/edit`}
                            aria-label={t`Edit`}
                            sx={{ backgroundColor: '#D6E4F2', color: '#012D72', borderRadius: '2px', margin: '5px' }}
                            onClick={() =>
                                handleEditCohort({
                                    id: selectedCohort.id,
                                    name: selectedCohort.name,
                                    analyticsDataset: selectedCohort.analyticsDataset
                                })
                            }
                        >
                            <EditOutlinedIcon />
                        </IconButton>
                    </LightTooltip>
                )
            }
            if (window.outerWidth < 1367 || window.innerWidth < 1367) {
                cardActions.push(
                    <LightTooltip title={isExpand ? t`Collapse` : t`Expand`} placement='top'>
                        <IconButton
                            key='distribution'
                            color='primary'
                            onClick={() => setIsExpand(!isExpand)}
                            sx={{ backgroundColor: '#D6E4F2', color: '#012D72', borderRadius: '2px', margin: '5px' }}
                        >
                            {isExpand ? <CloseFullscreenTwoToneIcon /> : <OpenInFullIcon />}
                        </IconButton>
                    </LightTooltip>
                )
            }
        }

        return (
            <CohortDashboardCohortCard
                cohort={selectedCohort!}
                actions={cardActions}
                sizeLoading={selectedCohort && sizeLoading[selectedCohort.id]}
            />
        )
    }

    const topBarStyle = {
        display: 'flex',
        alignItems: 'center',
        height: TOP_BAR_HEIGHT,
        padding: '10px',
        borderRadius: 0,
        background: 'white'
    }

    const useIsLargeScreen = () => {
        const [windowWidth, setWindowWidth] = useState(window.innerWidth)
        const theme = useTheme()

        useEffect(() => {
            const handleResize = () => {
                setWindowWidth(window.innerWidth)
            }

            window.addEventListener('resize', handleResize)

            // Clean up the event listener when the component unmounts
            return () => {
                window.removeEventListener('resize', handleResize)
            }
        }, [])

        return useMemo(() => windowWidth >= theme.breakpoints.values.lg, [theme.breakpoints.values.lg, windowWidth])
    }

    const useIsMediumScreen = () => {
        const [windowWidth, setWindowWidth] = useState(window.innerWidth)
        const theme = useTheme()

        useEffect(() => {
            const handleResize = () => {
                setWindowWidth(window.innerWidth)
            }

            window.addEventListener('resize', handleResize)

            // Clean up the event listener when the component unmounts
            return () => {
                window.removeEventListener('resize', handleResize)
            }
        }, [])

        return useMemo(() => windowWidth >= theme.breakpoints.values.md, [theme.breakpoints.values.md, windowWidth])
    }

    const useIsSmallScreen = () => {
        const [windowWidth, setWindowWidth] = useState(window.innerWidth)
        const theme = useTheme()

        useEffect(() => {
            const handleResize = () => {
                setWindowWidth(window.innerWidth)
            }

            window.addEventListener('resize', handleResize)

            // Clean up the event listener when the component unmounts
            return () => {
                window.removeEventListener('resize', handleResize)
            }
        }, [])

        return useMemo(() => windowWidth >= theme.breakpoints.values.sm, [theme.breakpoints.values.sm, windowWidth])
    }

    const greaterThanLargeScreen = useIsLargeScreen()
    const greaterThanMediumScreen = useIsMediumScreen()
    const greaterThanSmallScreen = useIsSmallScreen()

    const renderCohortInfo = (selectedCohort: CohortListItem) => (
        <>
            <Box flexGrow={2} alignSelf='flex-end'>
                <Box display='flex' flexDirection='column' gap={0}>
                    <Typography fontWeight='bold' fontSize={12} textTransform='uppercase' color='success.main'>
                        {selectedCohort?.analyticsDataset.name}
                    </Typography>
                    <Typography color='primary' fontSize={18} component='h3' noWrap sx={{ fontSize: '45px', lineHeight: '45px' }}>
                        {selectedCohort?.name}
                    </Typography>
                </Box>
            </Box>
            <Box flexGrow={8} alignSelf='flex-end'>
                <Grid container spacing={0} wrap='nowrap'>
                    {greaterThanSmallScreen && (
                        <Grid item xs={2} sx={{ paddingRight: '20px' }} minWidth={'fit-content'}>
                            <BasicCardStat
                                id={`cohort-grid-size-${selectedCohort.id}`}
                                label={<Trans>Cohort Size</Trans>}
                                value={
                                    <CohortSizeWithLoader
                                        {...selectedCohort}
                                        sizeLoading={sizeLoading[selectedCohort.id]}
                                        datasetSize={selectedCohort.analyticsDataset.size}
                                        cohortSize={selectedCohort?.cohortSize}
                                    />
                                }
                            />
                        </Grid>
                    )}
                    {greaterThanMediumScreen && (
                        <>
                            <Grid item xs={3} minWidth={'fit-content'}>
                                <BasicCardStat
                                    id={`cohort-grid-owner-${selectedCohort.id}`}
                                    label={<Trans>Owner</Trans>}
                                    value={selectedCohort.createdByEmail || ''}
                                />
                            </Grid>
                        </>
                    )}
                    {greaterThanLargeScreen && (
                        <>
                            <Grid item xs={2} minWidth={'fit-content'}>
                                <BasicCardStat
                                    id={`cohort-grid-created-${selectedCohort.id}`}
                                    label={<Trans>Created</Trans>}
                                    value={dateFormatter.format(new Date(selectedCohort.createdDttm))}
                                />
                            </Grid>
                            <Grid item xs={3} paddingRight={'10px'}>
                                <BasicCardStat
                                    id={`cohort-grid-version-${selectedCohort.id}`}
                                    label={<Trans>Dataset</Trans>}
                                    value={<DatasetSize name={selectedCohort.analyticsDataset.name} size={selectedCohort.analyticsDataset.size} />}
                                />
                            </Grid>
                            <Grid item xs={2} minWidth={'fit-content'} paddingRight={'10px'}>
                                <BasicCardStat
                                    id={`cohort-grid-updated-${selectedCohort.id}`}
                                    label={<Trans>Updated</Trans>}
                                    value={dateFormatter.format(new Date(selectedCohort.queryUpdatedDttm))}
                                />
                            </Grid>
                        </>
                    )}
                </Grid>
            </Box>
        </>
    )

    const TopBar = () => {
        return (
            <Paper sx={topBarStyle} elevation={2}>
                <Box flex={1} display='flex' gap={2} justifyContent='flex-start' alignItems='center'>
                    {!isCardInView && selectedCohort && greaterThanSmallScreen && renderCohortInfo(selectedCohort)}
                    {permissions?.includes(PlatformPermissions.CREATE_COHORT) && (
                        <FrameworkButton
                            variant='contained'
                            color='primary'
                            onClick={() => setCreateModalVisible(true)}
                            aria-label={t`Create Cohort`}
                            sx={{ marginLeft: '30px' }}
                        >
                            <AddIcon sx={{ height: '18.5px', width: '18.5px', margin: '0.1rem' }} />
                            <Trans>Create Cohort</Trans>
                        </FrameworkButton>
                    )}
                </Box>
            </Paper>
        )
    }
    const [isCardInView, setIsCardInView] = useState(true)

    const debounce = (func, delay) => {
        let timeoutId
        return (...args) => {
            clearTimeout(timeoutId)
            timeoutId = setTimeout(() => func(...args), delay)
        }
    }

    const debouncedSetIsCardInView = useRef(
        debounce((value) => {
            setIsCardInView(value)
        }, 500)
    ).current

    const cardRef = useRef<HTMLDivElement>(null)
    const cardIsVisible = useOnScreen(cardRef, { rootMargin: '-50px', threshold: 0.5 })
    useEffect(() => {
        if (cardRef.current) {
            debouncedSetIsCardInView(cardIsVisible)
        }
    }, [cardIsVisible, debouncedSetIsCardInView])

    const CohortList = (
        <DashboardCohortList
            actions={actions}
            cohortList={sortedCohorts}
            cohortSizeLoading={state.cohortSizeLoading}
            datasetsList={sortedDatasets}
            selectedCohort={selectedCohort}
            searchValue={routing.queryParams.search}
            selectedOwner={cohortOwner}
            handleSearchChange={handleSearchChange}
            onCreateCohortClick={() => setCreateModalVisible(true)}
            reportType={reportType ? reportType : ReportAnalyticsType.DEMOGRAPHICS}
            cohortsLoading={state.cohortsLoading}
            permissions={permissions}
            setCohortId={setCohortId}
            setCohortOwner={setCohortOwner}
            trackPage={actions.trackPage}
        />
    )

    const detailsRef = useRef<HTMLDivElement>(null)

    const CohortDashboardDetailsPane = (
        <div key={isExpand ? 'expanded' : 'collapsed'}>
            <Box position='relative'>
                <Box ref={cardRef}>
                    <Card reportType={reportType ?? ReportAnalyticsType.DEMOGRAPHICS} />
                </Box>
                <Box width='100%' flexDirection='column' marginBottom={1} sx={{ backgroundColor: '#F5F5F5' }} ref={detailsRef}>
                    {selectedCohort !== undefined && (
                        <DashboardCohortReports
                            cohortListItem={selectedCohort}
                            reportsActions={actions}
                            reportAnalyticsType={reportType || ReportAnalyticsType.DEMOGRAPHICS}
                            onReportAnalyticsTypeSelected={(reportAnalyticsType: ReportAnalyticsType) => {
                                setReportType(reportAnalyticsType)
                                const route = `/${CohortRoutes.COHORTBUILD}/${CohortRoutes.DASHBOARD}/${routing.pathParams.cohortOwner}/${selectedCohort.id}/${reportAnalyticsType}${location.search}`
                                actions.trackPage(route)
                                window.history.pushState({}, '', route)
                            }}
                        />
                    )}
                </Box>
            </Box>
        </div>
    )

    const handleCreateEditDialogSave = (cohort: CohortSaveValues) => {
        actions.cohortCreate(cohort)
    }

    const [cardHeight, setCardHeight] = useState(cardRef.current?.clientHeight || 0)

    useEffect(() => {
        const observer = new ResizeObserver((entries) => {
            for (let entry of entries) {
                const { height } = entry.contentRect
                setCardHeight(height)
            }
        })

        if (cardRef.current) {
            observer.observe(cardRef.current)
        }

        return () => {
            observer.disconnect()
        }
    }, [isExpand])

    const [reportsHeight, setReportsHeight] = useState(detailsRef?.current?.clientHeight || 0)

    useEffect(() => {
        const observer = new ResizeObserver((entries) => {
            for (let entry of entries) {
                const { height } = entry.contentRect
                setReportsHeight(height)
            }
        })

        if (detailsRef.current) {
            observer.observe(detailsRef.current)
        }

        return () => {
            observer.disconnect()
        }
    }, [isExpand])

    const maxHeight = useMemo(() => {
        return reportsHeight + cardHeight === 0 ? 'inherit' : reportsHeight + cardHeight + 24
    }, [reportsHeight, cardHeight])

    return (
        <Box display='flex' flexDirection='column'>
            <>
                {isExpand === false && (
                    <Box position={'fixed'} zIndex={'1000'} top={'56px'} right={'0px'} left={'65px'} sx={{ display: isExpand ? 'none' : 'block' }}>
                        <TopBar />
                    </Box>
                )}
                <Grid container style={{ marginTop: isExpand ? '0px' : '72px', display: '-webkit-inline-box' }}>
                    <Grid
                        item
                        xs={12}
                        md={3}
                        lg={2.5}
                        bgcolor='grey.300'
                        style={{
                            overflow: 'scroll',
                            zIndex: '-1',
                            maxHeight: window.innerWidth < theme.breakpoints.values.md ? `${window.innerHeight * 0.35}px` : maxHeight,
                            display: isExpand ? 'none' : 'block'
                        }}
                    >
                        {CohortList}
                    </Grid>
                    <Grid padding={3} item xs={12} md={isExpand ? 12 : 9} lg={isExpand ? 12 : 9.5}>
                        {CohortDashboardDetailsPane}
                    </Grid>
                </Grid>
            </>
            {createModalVisible && <WizardDialog onCancel={() => setCreateModalVisible(false)} onSave={handleCreateEditDialogSave} />}
        </Box>
    )
}
