import { cohortListActions } from '@om1/cohort-module/src/state'
import { CohortConditionsService, PaginatedDTO_CohortConditionDTO_ } from '@om1/falcon-api'
import { handleApiError } from '@om1/falcon-api/utils'
import { call, put } from 'redux-saga/effects'
import { rwaConditionsActions } from '../state/rwa-conditions'

export function createGetRWAConditionsSaga() {
    return function* (action: ReturnType<typeof rwaConditionsActions.getRwaConditions>) {
        try {
            yield put(rwaConditionsActions.setRwaConditionsLoading({ loading: true }))
            const paginatedCohortConditions: PaginatedDTO_CohortConditionDTO_ = yield call(
                CohortConditionsService.indexRwaInsightsCohortConditionsGet,
                {
                    cohortId: null,
                    page: undefined,
                    limit: undefined
                }
            )
            yield put(cohortListActions.cohortsGetSystemCohorts({ path: {}, query: {} }))
            yield put(rwaConditionsActions.setRwaConditions({ conditions: paginatedCohortConditions.data }))
        } catch (error) {
            yield handleApiError(error)
        } finally {
            yield put(rwaConditionsActions.setRwaConditionsLoading({ loading: false }))
        }
    }
}
